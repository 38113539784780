<template>
  <form class="form w-100 needs-validation" id="signInForm">
    <div class="text-center mb-10">
      <h1 class="mb-3">
        <span>
          {{ $config.localization[activeLanguage].auth.title }}
        </span>
      </h1>
      <div class="text-gray-500 text-center fw-semibold fs-5" v-if="false">
        {{ $t("SignIn.NewHere", {}, { locale: activeLanguage }) }}
        <router-link to="/sign-up" class="link-primary fw-bolder">
          {{ $t("SignIn.CreateanAccount", {}, { locale: activeLanguage }) }}
        </router-link>
      </div>
    </div>
    <div class="fv-row mb-10">
      <label class="form-label fs-6 fw-bolder required" for="email">{{
        $t("BaseModelFields.Email", {}, { locale: activeLanguage })
      }}</label>
      <div class="position-relative">
        <input
          data-autofocus="true"
          class="form-control form-control-lg"
          type="email"
          name="email"
          id="email"
          :autocomplete="$isAutoComplete"
          :spellcheck="$isTextSpellCheck"
          required
          :disabled="formPosting"
          v-on:keyup.enter="onSubmitLogin"
          v-model="userData.email"
        />
        <div class="invalid-tooltip">
          {{
            this.$t(
              "FieldWarnings.EmailNotValid",
              {},
              { locale: activeLanguage }
            )
          }}
        </div>
      </div>
    </div>
    <div class="fv-row mb-10">
      <div class="d-flex flex-stack mb-2">
        <label class="form-label fw-bolder fs-6 mb-0 required" for="password">{{
          $t("BaseModelFields.Password", {}, { locale: this.activeLanguage })
        }}</label>
        <router-link
          to="/password-reset"
          tabindex="-1"
          class="link-primary fs-6 fw-bolder"
        >
          {{ $t("SignIn.ForgotPassword", {}, { locale: this.activeLanguage }) }}
        </router-link>
      </div>
      <div class="position-relative">
        <input
          class="form-control form-control-lg"
          type="password"
          name="password"
          id="password"
          :autocomplete="$isAutoComplete"
          :disabled="formPosting"
          v-on:keyup.enter="onSubmitLogin"
          required
          v-model="userData.password"
        />
        <div class="invalid-tooltip">
          {{
            this.$t(
              "FieldWarnings.TextNotValid",
              {},
              { locale: this.activeLanguage }
            )
          }}
        </div>
      </div>
    </div>
    <div class="text-center">
      <button
        @click="onSubmitLogin"
        type="button"
        class="btn btn-lg btn-primary w-100 mb-5"
        v-if="!formPosting"
      >
        <span class="indicator-label">
          {{ $t("SignIn.Login", {}, { locale: this.activeLanguage }) }}
        </span>
      </button>
      <div class="spinner-border text-primary" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </form>
</template>
<script>
import $ from "jquery";
import { locale } from "devextreme/localization";
import {
  removeMoshaNotifications,
  removeModalBackdrops,
  removeModals,
  removeModalDependecyClassesAndStyles,
} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  name: "SignIn",
  data() {
    return {
      userData: {
        email: null,
        password: null,
        authRequestFromTypeId: 1,
      },
      formPosting: false,
      activeLanguage:
        localStorage.getItem("activeLang") || this.$store.state.activeLang,
    };
  },
  watch: {
    "$store.state.activeLang"() {
      this.activeLanguage = this.$store.state.activeLang;
      this.changeDocumentTitle();
    },
  },
  methods: {
    changeDocumentTitle() {
      this.$root.changeDocumentTitle(
        this.$t("BaseModelFields.Login", {}, { locale: this.activeLanguage })
      );
    },
    onSubmitLogin() {
      var form = $("form#signInForm"),
        self = this;
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        String.isNullOrWhiteSpace(this.userData.email) ||
        String.isNullOrWhiteSpace(this.userData.password)
      ) {
        return;
      }

      this.formPosting = true;
      this.$authAxios
        .post("/Authenticate", {
          email: this.$root.encrypt(this.userData.email, true),
          password: this.$root.encrypt(this.userData.password, true),
          authRequestFromTypeId: this.userData.authRequestFromTypeId,
        })
        .then((login_response) => {
          var response = login_response.data;
          if (response.isOk) {
            this.$store.commit("setUser", response);
            this.$store.commit("setActiveLang", response.activeLanguage);
            this.$i18n.locale = response.activeLanguage;
            locale(response.activeLanguage);

            this.$store.dispatch("addBodyClassName", "page-loading");

            this.$root.applyTheme();
            //get current user and redirecting
            this.$root.getCurrentUser(true);
          } else {
            this.formPosting = false;

            if (!String.isNullOrWhiteSpace(response.passwordResetToken)) {
              this.$router.push({
                name: "password-change",
                query: {
                  token: response.passwordResetToken,
                },
              });

              Swal.fire({
                html: `<span class='fw-bold fs-5'>${this.$t(
                  "PasswordChange.PasswordResetNeeded",
                  {},
                  { locale: this.activeLanguage }
                )}</span>`,
                icon: "error",
                showDenyButton: false,
                buttonsStyling: false,
                confirmButtonText: this.$t(
                  "BaseModelFields.Close",
                  {},
                  { locale: this.activeLanguage }
                ),
                customClass: {
                  confirmButton: "btn fw-bold btn-success",
                },
              });

              return;
            }

            var errorMessage = response.message,
              errorMessageIsNull = String.isNullOrWhiteSpace(errorMessage),
              passwordResetButtonShow =
                response.message ===
                process.env.VUE_APP_MEMBERSHIP_INVALID_EMAIL_OR_PASSWORD_KEY;
            if (errorMessageIsNull) {
              errorMessage = this.$t(
                "BaseModelFields.UnexpectedErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              );
            } else {
              errorMessage = this.$root.getMessageByKey(errorMessage);
            }

            Swal.fire({
              html: `<span class='fw-bold fs-5'>${errorMessage}</span>`,
              icon: "error",
              showDenyButton: passwordResetButtonShow,
              denyButtonText:
                "<i class='fa fa-lock fs-5'></i> " +
                this.$t(
                  "BaseModelFields.ForgotYourPassword",
                  {},
                  { locale: this.activeLanguage }
                ),
              buttonsStyling: false,
              confirmButtonText: this.$t(
                "BaseModelFields.TryAgain",
                {},
                { locale: this.activeLanguage }
              ),
              customClass: {
                confirmButton: "btn fw-bold btn-success",
                denyButton: "btn fw-bold btn-info me-2",
              },
              reverseButtons: true,
            }).then((result) => {
              if (result.isDenied) {
                self.$router.push({ name: "password-reset" });
              }
            });
          }
        })
        .catch((response) => {
          this.formPosting = false;
          var errorMessage = this.$t(
            "BaseModelFields.UnexpectedErrorHasOccurred",
            {},
            { locale: this.activeLanguage }
          );
          Swal.fire({
            html: `<span class='fw-bold fs-5'>${errorMessage}</span>`,
            icon: "error",
            showDenyButton: true,
            denyButtonText:
              "<i class='fa fa-users fs-5'></i> " +
              this.$t(
                "BaseModelFields.SupportTeam",
                {},
                { locale: this.activeLanguage }
              ),
            buttonsStyling: false,
            confirmButtonText: this.$t(
              "BaseModelFields.TryAgain",
              {},
              { locale: this.activeLanguage }
            ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
              denyButton: "btn fw-bold btn-info me-2",
            },
            reverseButtons: true,
          }).then((result) => {
            if (result.isDenied) {
              window.open(this.$config.webSites.support);
            }
          });
        });
    },
  },
  mounted() {
    let input = document.querySelector("[data-autofocus='true']");
    if (input) {
      input.focus();
    }

    this.$root.companyImportedDependenciesRemove();

    removeModalBackdrops();
    removeModals();
    removeModalDependecyClassesAndStyles();
  },
  unmounted() {
    removeMoshaNotifications();
    removeModalDependecyClassesAndStyles();
  },
  created() {
    this.changeDocumentTitle();
  },
};
</script>
